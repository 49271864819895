<template>
  <div>
    <div
      class="banner_info"
      style="
        background: url(images/3_02.jpg) center no-repeat;
        background-size: cover;
      "
    >
      <img src="images/3_03.png" />
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt>申请中心</dt>
              <dd>Application Center</dd>
            </dl>
            <ul>
              <li
                :class="{ on: item.id == cId }"
                v-for="(item, index) in leftData"
                :key="item.id"
                @click="itemClick(item, index)"
              >
                <a href="javascript:;">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="box" v-if="index == 1">
            <div class="title flex">
              <div class="flex_item"><b>培训申请</b></div>
            </div>
            <div class="form">
              <table width="100%">
                <tr>
                  <td width="85"><span class="redstar">*</span>单位名称：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="certTwo.businessName"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>传真：</td>
                  <td>
                    <input type="text" class="input" v-model="certTwo.fax" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>培训开始时间：</td>
                  <!--                  <td><input type="text" class="input" v-model="certTwo.beginTime"></td>-->
                  <td>
                    <el-date-picker
                      v-model="certTwo.beginTime"
                      type="datetime"
                      placeholder="培训开始时间"
                    >
                    </el-date-picker>
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>培训结束时间：</td>
                  <!--                  <td><input type="text" class="input" v-model="certTwo.endTime"></td>-->
                  <td>
                    <el-date-picker
                      v-model="certTwo.endTime"
                      type="datetime"
                      placeholder="培训结束时间"
                    >
                    </el-date-picker>
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>联系人：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certTwo.contact"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>联系电话：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="certTwo.telephone"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>申请时间：</td>
                  <!--                  <td><input type="text" class="input lg" v-model="certTwo.appleTime"></td>-->
                  <td>
                    <el-date-picker
                      v-model="certTwo.appleTime"
                      type="datetime"
                      placeholder="选择申请时间"
                    >
                    </el-date-picker>
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>其他信息：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certTwo.digest"
                    />
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <span class="redstar">*</span>参加培训内容：
                  </td>
                  <td>
                    <ul class="clearfix">
                      <li v-for="(item, index) in types">
                        <label
                          ><input type="checkbox" v-model="item.checked" />
                          {{ item.name }}</label
                        >
                      </li>
                    </ul>
                    <button type="button" class="btn" @click="send">
                      发送
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="box" v-else>
            <div class="title flex">
              <div class="flex_item"><b>认证申请</b></div>
            </div>
            <div class="form">
              <table width="100%">
                <tr>
                  <td width="85"><span class="redstar">*</span>申请人：</td>
                  <td>
                    <input type="text" class="input" v-model="certOne.name" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>联系电话：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="certOne.telephone"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>企业名称：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certOne.businessName"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>企业人数：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="certOne.businessNumber"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>电子邮箱：</td>
                  <td>
                    <input
                      type="text"
                      class="input md"
                      v-model="certOne.email"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>企业网站：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certOne.businessSites"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>公司地址：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certOne.businessAddress"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>邮政编码：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="certOne.postCode"
                    />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>认证范围：</td>
                  <td>
                    <input type="text" class="input" v-model="certOne.scope" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>其他信息：</td>
                  <td>
                    <input
                      type="text"
                      class="input lg"
                      v-model="certOne.digest"
                    />
                  </td>
                </tr>
                <tr>
                  <td valign="top">认证项目：</td>
                  <td>
                    <ul class="clearfix">
                      <li v-for="item in certification" :key="item.id">
                        <label
                          ><input type="checkbox" v-model="item.checked" />
                          {{ item.objectName }}</label
                        >
                      </li>
                    </ul>
                    <button type="button" class="btn" @click="send">
                      发送
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import { submitCultivate } from "@/network/api";

export default {
  name: "apply",
  data() {
    return {
      pid: 0,
      id: 0,
      index: 0,
      cId: 0,
      columnData: [],
      certification: [],
      certOne: {
        name: "",
        telephone: "",
        businessName: "",
        businessNumber: "",
        email: "",
        businessSites: "",
        businessAddress: "",
        postCode: "",
        scope: "",
        digest: "",
        object: "",
      },
      types: [
        { name: "QMS", checked: false },
        { name: "EMS/OHSMS", checked: false },
        { name: "QMS/EMS/OHSMS", checked: false },
      ],
      certTwo: {
        businessName: "",
        fax: "",
        beginTime: "",
        endTime: "",
        cultivate: "",
        contact: "",
        telephone: "",
        digest: "",
        appleTime: "",
      },
      leftData: [],
    };
  },
  mounted() {
    let route = this.$route.query;
    this.pid = route?.pid;
    this.id = route?.id;
    this.index = route?.index;
    console.log(this.id);
    this.$api.getChildColumn({ columnId: this.pid }).then((res) => {
      this.leftData = res.data.data;
      this.certificationObject();
      this.$nextTick(function () {
        this.cId = this.leftData[this.index].id;
      });
    });
  },
  methods: {
    ...mapActions(["columnAction"]),
    itemClick(item, i) {
      this.cId = item.id;
      this.index = i;
    },
    async findColumnData() {
      let res = await this.$api.getChildColumn({ columnId: this.cId });
      this.columnData = res.data.data;
      console.log(this.columnData, "--");
    },
    async certificationObject() {
      let res = await this.$api.certificationObject();
      this.certification = res.data.data;
      this.certification.forEach((item) => {
        item.checked = false;
      });
    },
    async send() {
      if (this.index == 0) {
        let checkedBtn = [];
        this.certification.forEach((item) => {
          if (item.checked) {
            checkedBtn.push(item.id);
          }
        });
        console.log(this.certOne);
        this.certOne.object = checkedBtn.join(",");
        //认证申请
        let res = await this.$api.submitCertification(this.certOne);
        if (res.data.code == 200) {
          this.$message({
            message: "申请成功",
            type: "success",
          });
          for (let item in this.certOne) {
            this.certOne[item] = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } else {
        //培训申请
        let checkedBtn = [];
        this.types.forEach((item) => {
          if (item.checked) {
            checkedBtn.push(item.name);
          }
        });
        this.certTwo.cultivate = checkedBtn.join(",");
        //认证申请
        let res = await this.$api.submitCultivate(this.certTwo);
        if (res.data.code == 200) {
          this.$message({
            message: "申请成功",
            type: "success",
          });
          for (let item in this.certTwo) {
            this.certTwo[item] = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }
    },
  },
  computed: {
    ...mapState(["cacheColumn"]),
  },
};
</script>

<style scoped>
/deep/ .el-input__inner {
  background: #f5f5f6 !important;
  border: 1px solid #d8eaff !important;
  height: 34px;
  border-radius: 34px;
}
/deep/.el-input__icon {
  line-height: 34px;
}
</style>
